@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville');

.footerDiv {
	margin: 0;
	position: fixed;
	bottom: 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-content: center;
	height: 10vh;
	width: 100vw;
	background-color: white;
	border-top: solid 0.5px #a4a4a4;
}

.pFooterDiv {
	font-family: 'Libre Baskerville';
	font-style: italic;
	font-weight: 400;
	font-size: 16px;
	line-height: 15px;
	letter-spacing: 0.15em;
	color: #000000;
}

.devDiv{
	font-family: 'Libre Baskerville';
	font-style: italic;
	font-weight: 400;
	font-size: 8px;
	line-height: 15px;
	letter-spacing: 0.15em;
	color: #000000;
}


/* ---------MOBILE--------- */

@media (max-width: 450px) {
	.footerDiv {
		margin: 0;
		position: fixed;
		bottom: 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		align-content: center;
		height: 60px;
		width: 100vw;
		background-color: white;
		border-top: solid 0.5px #a4a4a4;
	}

	.pFooterDiv {
		font-family: 'Libre Baskerville';
		font-style: italic;
		font-weight: 400;
		font-size: 12px;
		line-height: 15px;
		letter-spacing: 0.15em;
		color: #000000;
	}

	.devDiv{
		font-family: 'Libre Baskerville';
		font-style: italic;
		font-weight: 400;
		font-size: 8px;
		line-height: 15px;
		letter-spacing: 0.15em;
		color: #000000;
	}
}
