/* ---------MOBILE--------- */

.dashboardDiv{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
    margin-top: 20vh;
    margin-bottom: 10vh;
}

.h3Dashboard {
    font-family: 'Libre Baskerville';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	text-align: center;
	letter-spacing: 0.5em;
	color: #e66a6a;
	border-top: 1px solid #f19d9d;
	border-bottom: 1px solid #f19d9d;
	padding: 1rem;
	margin-bottom: 5%;
	
}

.guestsDiv {
    display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.h4Dashboard{
    font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 24px;
	text-align: center;
	color: #4e4e4e;
	margin-bottom: 5%;
    border-bottom: 1px solid #f19d9d;
}

.summaryDiv{
    font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	color: #4e4e4e;
}

.guestsIcons{
    display: grid; 
    grid-template-columns: 3fr 1fr 1fr 1fr 1fr; 
    grid-template-rows: 1fr; 
    gap: 0px 0px; 
    justify-items: center;
    width: 90vw;
    color: #e66a6a;
    padding: 1rem;
    border-bottom: 1px solid #f19d9d;
    text-align: left;
    font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
}


/* Form */

.rsvpDiv {
	margin: 10%;
	display: flex;
	/* flex-direction: column; */
	justify-content: center;
	align-items: center;
}

.inputPill{
	margin-bottom: 7%;
	width: 200px;
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	text-align: left;
	color: #9e8d8d;
	background: #F4F4F4;
	border-radius: 50px;
	border-style: none;
	padding-top: 14px;
	padding-left: 25px;
	padding-bottom: 14px;
	padding-right: 25px;
	cursor: pointer;
}

.h4Div {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	text-decoration: underline;
	color: #4e4e4e;
	margin-bottom: 5%;
}
.smallButton {
	margin-bottom: 7%;
    width: 150px;
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	color: #9e8d8d;
	background: #f9e6e6;
	border-radius: 50px;
	border-style: none;
	padding-top: 14px;
	padding-left: 25px;
	padding-bottom: 14px;
	padding-right: 25px;
	cursor: pointer;
}

.cbRound {
	width: 1.3em;
	height: 1.3em;
	background-color: white;
	border-radius: 50%;
	vertical-align: middle;
	border: 1px solid #ddd;
	appearance: none;
	-webkit-appearance: none;
	outline: none;
	cursor: pointer;
}

.rsvpForm {
    margin-bottom: 10%;

}

.cbRound:checked {
	background-color: #9e8d8d;
}

.formSpan {
    font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #4e4e4e;
	
}


/* ---------- tablet & desktop--------- */