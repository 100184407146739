@import '@fortawesome/fontawesome-free/css/all.css';

.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  height: 100vh;
}

