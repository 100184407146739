/* ---------MOBILE--------- */

.h3Div {
	font-family: 'Libre Baskerville';
	font-style: normal;
	font-weight: 700;
	font-size: 15px;
	line-height: 16px;
	text-align: center;
	letter-spacing: 0.5em;
	color: #e66a6a;
	border-top: 1px solid #f19d9d;
	border-bottom: 1px solid #f19d9d;
	padding: 1rem;
	margin-bottom: 15%;
	width: 100vw;
}

.rsvpDiv {
	margin: 8%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.inputPill {
	margin-bottom: 7%;
	width: 200px;
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	text-align: left;
	color: #9e8d8d;
	background: #F4F4F4;
	border-radius: 50px;
	border-style: none;
	padding-top: 14px;
	padding-left: 22px;
	padding-bottom: 14px;
	padding-right: 22px;
	cursor: pointer;
}

.pPlease {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	color: #4e4e4e;
	max-width:  300px;
}

.h4Div {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	color: #4e4e4e;
	margin-bottom: 5%;
}

.h5Div {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: left;
	color: #4e4e4e;
	margin-top: 0;
	margin-bottom: 5%;
	padding-left: 5%;
}

.smallButton {
	margin-bottom: 7%;
	width: 100px;
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	color: #9e8d8d;
	background: #f9e6e6;
	border-radius: 50px;
	border-style: none;
	padding-top: 14px;
	padding-left: 25px;
	padding-bottom: 14px;
	padding-right: 25px;
	cursor: pointer;
}

.smallButton:active {
	transform: scale(0.95);
}

.cbRound {
	width: 1.3em;
	height: 1.3em;
	background-color: white;
	border-radius: 50%;
	vertical-align: middle;
	border: 1px solid #ddd;
	appearance: none;
	-webkit-appearance: none;
	outline: none;
	cursor: pointer;
}

.cbRound:checked {
	background-color: #9e8d8d;
}

.rsvpForm {
	margin-bottom: 10%;

}

.formDiv {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	gap: 0px 0px;
	grid-template-areas:
		". ."
		". .";
		justify-items: start; 
	margin-bottom: 15%;
	border-bottom: solid 1px #4e4e4e;
	padding-bottom: 10%;
}

.formMenuDiv {
	display: flex;
	margin-bottom: 15%;
	border-bottom: solid 1px #4e4e4e;
	padding-bottom: 10%;
	text-align: left;
}

.soyPlusDiv {
	display: flex;
	flex-direction: column;
}

.plus1Div {
	border-left: 1px solid #4e4e4e;
	margin-left: 10px
}


.formSpan {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 30px;
	color: #4e4e4e;

}

.imgWorld {
	max-width: 100px;
}

/* ---------- tablet & desktop--------- */

@media (min-width: 451px) {

	.h3Div {
		font-family: 'Libre Baskerville';
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 16px;
		text-align: center;
		letter-spacing: 0.5em;
		color: #e66a6a;
		border-top: 1px solid #f19d9d;
		border-bottom: 1px solid #f19d9d;
		padding: 1rem;
		margin-bottom: 3%;
		width: 100vw;
	}

	.rsvpDiv {
		margin: 5%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.inputPill {
		margin-bottom: 4%;
		width: 220px;
		font-family: 'Source Sans Pro';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 20px;
		text-align: left;
		color: #9e8d8d;
		background: #F4F4F4;
		border-radius: 50px;
		border-style: none;
		padding-top: 14px;
		padding-left: 22px;
		padding-bottom: 14px;
		padding-right: 22px;
		cursor: text;
	}

	.h4Div {
		font-family: 'Poppins';
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		line-height: 24px;
		text-align: center;
		color: #4e4e4e;
		margin-bottom: 5%;
	}

	.smallButton {
		margin-bottom: 0%;
		width: 150px;
		font-family: 'Source Sans Pro';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 20px;
		text-align: center;
		color: #9e8d8d;
		background: #f9e6e6;
		border-radius: 50px;
		border-style: none;
		padding-top: 14px;
		padding-left: 25px;
		padding-bottom: 14px;
		padding-right: 25px;
		cursor: pointer;
	}

	.cbRound {
		width: 1.3em;
		height: 1.3em;
		background-color: white;
		border-radius: 50%;
		vertical-align: middle;
		border: 1px solid #ddd;
		appearance: none;
		-webkit-appearance: none;
		outline: none;
		cursor: pointer;
	}

	.cbRound:checked {
		background-color: #9e8d8d;
	}

	.rsvpForm {
		margin-bottom: 3%;

	}


	.formSpan {
		font-family: 'Poppins';
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 24px;
		color: #4e4e4e;
	}

	.imgWorld {
		max-width: 150px;
	}
}