@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville');

/* ---------DESKTOP & TABLET--------- */

.navbarDiv {
	margin: 0;
	position: fixed;
	top: 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-content: flex-end;
	height: 15vh;
	width: 100vw;
	background-color: white;
	border-bottom: solid 0.5px #a4a4a4;
}

.pNavbarDiv {
	height: 43.33px;
	font-family: 'Libre Baskerville';
	font-style: italic;
	font-weight: 400;
	font-size: 30px;
	line-height: 20px;
	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: 0.3em;
}


/* ---------MOBILE--------- */
@media (max-width: 450px) {
	.navbarDiv {
		margin: 0;
		position: fixed;
		top: 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-content: flex-end;
		height: 101.33px;
		width: 100vw;
	background-color: white;
	border-bottom: solid 0.5px #a4a4a4;
	}

	.pNavbarDiv {
        height: 43.33px;
        font-family: 'Libre Baskerville';
		font-style: italic;
		font-weight: 400;
		font-size: 20px;
		line-height: 20px;
		display: flex;
		align-items: center;
		text-align: center;
		letter-spacing: 0.15em;
	}
}
