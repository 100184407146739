@import url('https://fonts.googleapis.com/css2?family=Poppins');

.landingDiv {
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: center;
}

.pLandingDiv {
	margin: 39px;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 24px;
	text-align: center;
	letter-spacing: 0.5em;
	text-transform: uppercase;
	color: #4e4e4e;
}

a:link {
	text-decoration: none;
	color: #4e4e4e;
}

a:visited {
	text-decoration: none;
	color: #4e4e4e;
}

a:hover {
	text-decoration: none;
	color: #4e4e4e;
}

a:active {
	text-decoration: none;
	color: #4e4e4e;
}

/* ---------MOBILE--------- */

@media (max-width: 450px) {
	.landingDiv {
		display: flex;
		flex-direction: column;
		height: 100vh;
		justify-content: center;
	}

	.pLandingDiv {
        margin: 39px;
		font-family: 'Poppins';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		letter-spacing: 0.5em;
		text-transform: uppercase;
		color: #4e4e4e;
	}

	a:link {
		text-decoration: none;
		color: #4e4e4e;
	}

	a:visited {
		text-decoration: none;
		color: #4e4e4e;
	}

	a:hover {
		text-decoration: none;
		color: #4e4e4e;
	}

	a:active {
		text-decoration: none;
		color: #4e4e4e;
	}
}
